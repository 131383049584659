<template>
  <v-dialog style="z-index:999999" v-model="show">
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Datenschutz</v-toolbar-title>
        <v-spacer/>
        <v-btn @click="toggleDatenschutz" icon="mdi-close"></v-btn>
      </v-toolbar>
<v-container class="py-15">
  <h1>Datenschutzerklärung</h1>
  <h2>A) Allgemeine Informationen</h2>

  <br>
  <strong>Name und Kontaktdaten des Verantwortlichen</strong>

  <p>Landratsamt Eichst&auml;tt</p><p> Residenzplatz 1 </p><p>85072 Eichst&auml;tt</p>

  <p>Telefon: (08421) 70-0</p><p> Telefax: (08421) 70-222</p>

  <p>E-Mail: poststelle@lra-ei.bayern.de </p><p> De-Mail: poststelle@lra-ei.de-mail.de</p>
  <br>
  <strong>Kontaktdaten des Datenschutzbeauftragten</strong>

  <p>Sie erreichen unseren Datenschutzbeauftragten unter:</p>

  <p>Landratsamt Eichst&auml;tt</p> <p>Residenzplatz 1</p><p> 85072 Eichst&auml;tt</p>

  <p>Telefon: 08421/70-0 E-Mail: datenschutz@lra-ei.bayern.de</p>
  <br>
  <strong>Zwecke und Rechtsgrundlagen f&uuml;r die Verarbeitung personenbezogener Daten</strong>

  <p>Zweck der Verarbeitung ist die Erf&uuml;llung der uns vom Gesetzgeber zugewiesenen &ouml;ffentlichen Aufgaben, insbesondere der Information der &Ouml;ffentlichkeit.</p>

  <p>Die Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer Daten ergibt sich, soweit nichts anderes angegeben ist, aus Art. 4 Abs. 1 des Bayerischen Datenschutzgesetzes (BayDSG) in Verbindung mit Art. 6 Abs. 1 UAbs. 1 Buchst. e der Datenschutzgrundverordnung (DSGVO). Demnach ist es uns erlaubt, die zur Erf&uuml;llung einer uns obliegenden Aufgabe erforderlichen Daten zu verarbeiten.</p>
  <br>
  <strong>Empf&auml;nger von personenbezogenen Daten</strong>

  <p>Der technische Betrieb unserer Datenverarbeitungssysteme erfolgt durch das Landratsamt Eichst&auml;tt, Sachgebiet Informations- und Kommunikationstechnik.</p>

  <p>Gegebenenfalls werden Ihre Daten an die zust&auml;ndigen Aufsichts- und Rechnungspr&uuml;fungsbeh&ouml;rden zur Wahrnehmung der jeweiligen Kontrollrechte &uuml;bermittelt.</p>

  <p>Zur Abwehr von Gefahren f&uuml;r die Sicherheit in der Informationstechnik k&ouml;nnen bei elektronischer &Uuml;bermittlung Daten an das Landesamt f&uuml;r Sicherheit in der Informationstechnik weitergeleitet werden und dort auf Grundlage der Art. 12 ff. des Bayerischen E-Government-Gesetzes verarbeitet werden.</p>
  <br>
  <strong>Dauer der Speicherung der personenbezogenen Daten</strong>

  <p>Ihre Daten werden nur so lange gespeichert, wie dies unter Beachtung gesetzlicher Aufbewahrungsfristen zur Aufgabenerf&uuml;llung erforderlich ist.</p>
  <br>
  <strong>Ihre Rechte</strong>

  <p>Soweit wir von Ihnen personenbezogene Daten verarbeiten, stehen Ihnen als Betroffener nachfolgende Rechte zu:</p>

  <p>Sie haben das Recht auf Auskunft &uuml;ber die zu Ihrer Person gespeicherten Daten (Art. 15 DSGVO). Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO). Liegen die gesetzlichen Voraussetzungen vor, so k&ouml;nnen Sie die L&ouml;schung oder Einschr&auml;nkung der Verarbeitung verlangen (Art. 17 DSGVO und Art. 18 DSGVO). Wenn Sie in die Verarbeitung eingewilligt haben oder ein Vertrag zur Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren durchgef&uuml;hrt wird, steht Ihnen gegebenenfalls ein Recht auf Daten&uuml;bertragbarkeit zu (Art. 20 DSGVO). Falls Sie in die Verarbeitung eingewilligt haben und die Verarbeitung auf dieser Einwilligung beruht, k&ouml;nnen Sie die Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Datenverarbeitung wird durch diesen nicht ber&uuml;hrt. Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Ihrer Daten Widerspruch einzulegen, wenn die Verarbeitung ausschlie&szlig;lich auf Grundlage des Art. 6 Abs. 1 Buchst. e oder f DSGVO erfolgt (Art. 21 Abs. 1 Satz 1 DSGVO).</p>

  <p>Beschwerderecht bei der Aufsichtsbeh&ouml;rde</p>

  <p>Weiterhin besteht ein Beschwerderecht beim Bayerischen Landesbeauftragten f&uuml;r den Datenschutz. Diesen k&ouml;nnen Sie unter folgenden Kontaktdaten erreichen:</p>

  <p>Postanschrift: </p><p>Postfach 22 12 19,</p><p> 80502 M&uuml;nchen Adresse: Wagm&uuml;llerstra&szlig;e 18,</p><p> 80538 M&uuml;nchen</p>

  <p>Telefon: 089 212672-0</p><p> Telefax: 089 212672-50</p>

  <p>E-Mail: poststelle@datenschutz-bayern.de</p><p> Internet: https://www.datenschutz-bayern.de/</p>
<br>
  <strong>Weitere Informationen</strong>

  <p>F&uuml;r n&auml;here Informationen zur Verarbeitung Ihrer Daten und zu Ihren Rechten k&ouml;nnen Sie uns unter den oben (zu Beginn von A) genannten Kontaktdaten erreichen.</p>
<br>
  <h2>B) Informationen zum Internetauftritt</h2>

  <p>Technische Umsetzung</p>

  <p>Unser Webserver wird durch das Landratsamt Eichst&auml;tt betrieben. Die von Ihnen im Rahmen des Besuchs unseres Webauftritts &uuml;bermittelten personenbezogenen Daten werden daher in unserem Auftrag durch das</p>

  <p>Landratsamt Eichst&auml;tt Sachgebiet Informations- und Kommunikationstechnik Residenzplatz 1 85072 Eichst&auml;tt</p>

  <p>Telefon: (08421) 70-0 Telefax: (08421) 70-222 </p><p> E-Mail: edv@lra-ei.bayern.de</p>

  <p>verarbeitet.</p>

  <p>Der M4-Merkur (unser Online Bestell- und Downloadsystem) wird bereit gestellt von: Raab-Software Dr. David Raab Gottfried-K&ouml;lwel-Platz 1 D-93176 Beratzhausen Tel: (+49) (0) 9493 378 Fax: (+49) (0)322 298 773 58 E-Mail: info@raab-software.de F&uuml;r die Nutzung unseres Bestellsystems leiten wir auf den M4-Merkur der Fa. Raab Software um. Bitte beachten Sie die dort aufgef&uuml;hrte Datenschutzerkl&auml;rung.</p>
<br/>
  <strong>Protokollierung</strong>

  <p>Wenn Sie diese oder andere Internetseiten aufrufen, &uuml;bermitteln Sie &uuml;ber Ihren Internetbrowser Daten an unseren Webserver. Die folgenden Daten werden w&auml;hrend einer laufenden Verbindung zur Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver aufgezeichnet:</p>

  <p>&bull; Datum und Uhrzeit der Anforderung &bull; Name der angeforderten Datei &bull; Seite, von der aus die Datei angefordert wurde &bull; Zugriffsstatus (Datei &uuml;bertragen, Datei nicht gefunden, etc.) &bull; verwendete Webbrowser und verwendetes Betriebssystem &bull; vollst&auml;ndige IP-Adresse des anfordernden Rechners &bull; &uuml;bertragene Datenmenge.</p>

  <p>Aus Gr&uuml;nden der technischen Sicherheit, insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver, werden diese Daten von uns gespeichert. Danach werden die Daten unverz&uuml;glich durch Verk&uuml;rzung der IP-Adresse auf Domain-Ebene anonymisiert, so dass es nicht mehr m&ouml;glich ist, einen Bezug auf einzelne Nutzer herzustellen. Die Daten werden ausschlie&szlig;lich zu diesem Zweck und in anonymisierter Form zu statistischen Zwecken verarbeitet; ein Abgleich mit anderen Datenbest&auml;nden oder eine Weitergabe an Dritte, auch in Ausz&uuml;gen, findet nicht statt.</p>
<br/>
  <strong> Cookies</strong>

  <p>Beim Zugriff auf dieses Internetangebot werden von uns Cookies (kleine Textdateien) auf Ihrer Festplatte mit einer G&uuml;ltigkeitsdauer von 28 Tagen gespeichert. Wir verwenden diese ausschlie&szlig;lich dazu, Ihren Browser bei diesem und weiteren Besuchen unserer Webseite wiederzuerkennen. Zweck dieser Wiedererkennung ist es, den Nutzern die Verwendung unserer Internetseite zu erleichtern. Cookies enthalten keine Viren und richten keinen Schaden an. Die meisten Browser sind so eingestellt, dass sie die Verwendung von Cookies akzeptieren, diese Funktion kann aber durch die Einstellung des Internetbrowsers von Ihnen f&uuml;r die laufende Sitzung oder dauerhaft abgeschaltet werden. Beachten Sie in diesem Fall die Einstellungsm&ouml;glichkeiten Ihres Browsers. Sie k&ouml;nnen bereits gesetzte Cookies jederzeit &uuml;ber Ihren Browser deaktivieren oder l&ouml;schen. Wenn Sie Cookies f&uuml;r unsere Internetseite deaktivieren, k&ouml;nnen m&ouml;glicherweise nicht mehr alle Funktionen genutzt werden.</p>
<br>
  <strong> Aktive Komponenten</strong>

  <p>Im Informationsangebot des Landratsamtes Eichst&auml;tt werden aktive Komponenten wie Javascript verwendet. Diese Funktion kann durch die Einstellung Ihres Internetbrowsers von Ihnen abgeschaltet werden.</p>
<br/>
  <strong> Elektronische Post (E-Mail)</strong>

  <p>Informationen, die Sie unverschl&uuml;sselt per Elektronische Post (E-Mail) an uns senden, k&ouml;nnen m&ouml;glicherweise auf dem &Uuml;bertragungsweg von Dritten gelesen werden. Wir k&ouml;nnen in der Regel auch Ihre Identit&auml;t nicht &uuml;berpr&uuml;fen und wissen nicht, wer sich hinter einer E-Mail-Adresse verbirgt. Eine rechtssichere Kommunikation durch einfache E-Mail ist daher nicht gew&auml;hrleistet. Wir setzen &ndash; wie viele E-Mail-Anbieter &ndash; Filter gegen unerw&uuml;nschte Werbung (&#8222;SPAM-Filter&ldquo;) ein, die in seltenen F&auml;llen auch normale E-Mails f&auml;lschlicherweise automatisch als unerw&uuml;nschte Werbung einordnen und l&ouml;schen. E-Mails, die sch&auml;digende Programme (&#8222;Viren&ldquo;) enthalten, werden von uns in jedem Fall automatisch gel&ouml;scht.</p>

  <p>Wenn Sie schutzw&uuml;rdige Nachrichten an uns senden wollen, empfehlen wir, die Nachricht auf konventionellem Postwege an uns zu senden.</p>

  <p>Sofern Sie mit einer unverschl&uuml;sselten Antwort per E-Mail auf Ihr Schreiben nicht einverstanden sind, bitten wir um einen ausdr&uuml;cklichen Hinweis.</p>
<br/>
  <strong> Online-Angebote</strong>

  <p>In unserem Angebot besteht die M&ouml;glichkeit zur Eingabe pers&ouml;nlicher oder gesch&auml;ftlicher Daten (E-Mail-Adressen, Namen, Anschriften). Die Nutzung der angebotenen Serviceleistungen und Dienste seitens des Nutzers erfolgt ausdr&uuml;cklich auf freiwilliger Basis. Evtl. in diesem Zusammenhang &ndash; ggf. auch durch beauftragte Dritte &ndash; gespeicherte Daten werden nur im Rahmen der jeweiligen Serviceleistung eine Weitergabe an Dritte erfolgt nicht.</p>

  <p></p>
<br>
  <strong>Er&ouml;ffnung des Zugangs</strong>

  <p>Der Landkreis Eichst&auml;tt bietet M&ouml;glichkeiten zur elektronischen Kommunikation an. F&uuml;r den Bereich der Verwaltungsverfahren richtet sich die elektronische Kommunikation nach Art. 3 a des Bayerischen Verwaltungsverfahrensgesetzes (BayVwVfG) und nach Art. 3 des Bayerischen E-Government-Gesetzes. Der Landkreis hat diesen Zugang nach Ma&szlig;gabe der folgenden technischen Rahmenbedingungen er&ouml;ffnet.</p>
<br>
  <strong> Mailadressen</strong>

  <p>F&uuml;r die elektronische Kommunikation per E-Mail sind folgende E-Mail-Adressen eingerichtet:</p>

  <p>poststelle@lra-ei.bayern.de</p><p> poststelle@lra-ei.de-mail.de</p>
<br>
  <strong>Schriftformersetzende Dokumente</strong>

  <p>Eine durch Rechtsvorschrift angeordnete Schriftform kann, soweit nicht durch Rechtsvorschrift etwas anderes bestimmt ist, durch die elektronische Form ersetzt werden. Der elektronischen Form gen&uuml;gt nur ein elektronisches Dokument, das mit einer qualifizierten elektronischen Signatur nach dem Signaturgesetz versehen ist (Art. 3a Abs. 2 Satz 1, 2 BayVwVfG) oder in einer schriftformersetzenden Form nach Art. 3a Abs. 2 Satz 4 BayVwVfG &uuml;bermittelt wird.</p>
<br/>
  <strong>Einlegung eines Rechtsbehelfs (z.B. Widerspruch)</strong>

  <p>Die Einlegung eines Rechtsbehelfs beim Landratsamt per einfacher E-Mail ist nicht zugelassen und entfaltet keine rechtlichen Wirkungen.</p>

  <p>Ein beim Landratsamt elektronisch eingelegter Rechtsbehelf muss mit einer qualifizierten elektronischen Signatur nach dem Signaturgesetz versehen unter der E-Mail-Adresse poststelle@lra-ei.bayern.de eingelegt werden oder per absenderbest&auml;tigter De-Mail unter der E-Mail-Adresse poststelle@lra-ei.de-mail.de eingereicht werden.</p>
<br>
  <strong>Verschl&uuml;sselung</strong>

  <p>Der Landkreis Eichst&auml;tt kann aus technischen und organisatorischen Gr&uuml;nden zurzeit noch keine verschl&uuml;sselten E-Mails entschl&uuml;sseln. Falls Sie uns vertrauliche Informationen senden wollen, bitten wir Sie, hierzu die Briefpost zu verwenden.</p>
<br>
  <strong>Erhebung personenbezogener Daten</strong>

  <p>Personenbezogene Daten werden nur erhoben, wenn Sie uns diese im Rahmen Ihres E-Mail-Kontakts/Kontaktformulars (z.B. Ihr Name und Ihre Adresse oder Ihr E-Mail-Adresse) freiwillig mitteilen. Wir verwenden die von Ihnen mitgeteilten Daten ohne Ihre gesonderte Einwilligung ausschlie&szlig;lich zur Erf&uuml;llung und Abwicklung Ihrer Anfrage. Diese Daten werden nur solange gespeichert, bis der Zweck erf&uuml;llt ist, zu dem sie uns anvertraut wurden. Sollten Sie mit der Speicherung Ihrer personenbezogenen Daten nicht mehr einverstanden oder diese unrichtig geworden sein, werden wir auf eine entsprechende Weisung hin die L&ouml;schung oder Sperrung Ihrer Daten veranlassen oder die notwendigen Korrekturen vornehmen (soweit dies nach dem geltendem Recht m&ouml;glich ist).</p>
<br>
  <strong>Information, Ansprechpartner</strong>

  <p>Diese Hinweise gelten nur f&uuml;r die Kommunikation mit dem Landkreis Eichst&auml;tt und gelten nicht f&uuml;r Verweise auf Angebote von Dritten, wie z.B. anderen Beh&ouml;rden.</p>

  <p>Sofern eine E-Mail nicht verarbeitbar ist, werden Sie durch den Empf&auml;nger dar&uuml;ber informiert. Dieser Fall kann z.B. durch Computerviren, allgemeine technische Probleme oder Abweichungen von den vorstehenden technischen Rahmenbedingungen ausgel&ouml;st werden.</p>

  <p>Sollten Sie Fragen zur Verschl&uuml;sselung und elektronischen Signatur haben, wenden Sie sich bitte an folgende E-Mail-Adresse: edv@lra-ei.bayern.de</p>
<br/>
  <strong>Sie haben noch Fragen?</strong>

  <p>Wenn Sie noch Fragen zum Datenschutz im Zusammenhang mit dieser Internetseite haben, k&ouml;nnen Sie sich an unseren Datenschutzbeauftragten wenden:</p>

  <p>Landratsamt Eichst&auml;tt</p><p> Residenzplatz 1 </p><p>85072 Eichst&auml;tt</p>

  <p>Telefon: 08421/70-0</p><p> E-Mail: datenschutz@lra-ei.bayern.de</p>
<br/>
  <small>Der beh&ouml;rdliche Datenschutzbeauftragte hat diese Internetseite im Hinblick auf die Einhaltung datenschutzrechtlicher Vorschriften &uuml;berpr&uuml;ft und freigegeben. Auf Antrag erhalten Sie Auskunft &uuml;ber die zu Ihrer Person gespeicherten Daten und k&ouml;nnen unrichtige Daten berichtigen lassen (Art. 10 und 11 Bayer. Datenschutzgesetz). Sofern die gesetzlichen Voraussetzungen vorliegen, k&ouml;nnen Sie auch eine Sperrung oder L&ouml;schung der gespeicherten Daten verlangen (Art. 12 Bayer. Datenschutzgesetz). Weitere Informationen zum Thema Datenschutz finden Sie auch auf den Internetseiten des Bayerischen Landesbeauftragten f&uuml;r den Datenschutz (www.datenschutz-bayern.de)</small>
</v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {useAppStore} from "@/stores/app.module";

export default {
  name: "DatenschutzView",
  setup(){
    const appStore = useAppStore();
    return {
      appStore
    }
  },
  methods:{
    toggleDatenschutz(){
      this.appStore.toggleDatenschutz();
    }
  },
  computed:{
    show(){
      return this.appStore.showDatenschutz;
    }
  }
}
</script>

<style scoped>

</style>