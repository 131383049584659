<template>
  <v-sheet class="rounded-circle" color="transparent" width="0" height="0" style="
          position:absolute;

          bottom:10%;
          left:50%;

          transform: rotateX(70deg);
          -webkit-box-shadow: -4px 95px 58px 150px rgba(0,0,0,0.1);
          -moz-box-shadow: -4px 95px 58px 150px rgba(0,0,0,0.1);
          box-shadow: -4px 95px 58px 150px rgba(0,0,0,0.1);"></v-sheet>
</template>

<script>
export default {
  name: "ItemShadow"
}
</script>

<style scoped>

</style>